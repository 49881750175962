<template>
	<Transition name="slide-fade" appear>
    <div>
        <div class="px-2 sm:px-6 lg:px-4 text-t3">
          <h2 class="text-2xl font-bold sm:text-3xl">
				Disputes
			</h2>
        <div class="shadow-t3 mt-4 bg-white p-6 md:px-12 rounded">
          <div>
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <div class="mb-4 justify-end form-check flex form-switch ">
                        <label class="pr-4 form-check-label inline-block text-t3 " for="ShowReports">Show Valid Only</label>
                        <input v-model="Invalids"  class="form-check-input appearance-none w-9  rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="ShowReports" checked>
                    </div>
                    <div class="overflow-hidden">
                        <table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left">Dispute ID</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left">Issued by</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left">Responsible</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left">Status</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left">Type</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left">Date</th>
                            <th  scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left "></th>
                            </tr>
                        </thead>
                        <tbody>
                          
                            <tr v-for="dispute in valDispute" :key="dispute.id" class="border-b">
                              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{{dispute.dispute_id}}</td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <p >{{dispute.creator_name}}</p>
                              <p v-if="!dispute.creator_name">Automated</p>
                              </td>
                            <td  class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <p v-if="dispute.third_assessor">{{dispute.third_assessor.full_name}}</p>
                              <p v-if="!dispute.third_assessor">Not Assigned</p>
                              </td>
                              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {{ dispute.status === 'completed' ? "Awaiting AM's approval" : dispute.status }}
                              </td>
                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{{dispute.type}}</td>
                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <p>Issued : {{new Date(dispute.raised).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}</p>
                                <p v-if="dispute.closed">Closed : {{new Date(dispute.closed).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}</p>
                            </td>
                            <td  class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <router-link :to="{name:'DisputeDetails', params:{id: dispute.id}  }" class="text-t3-teritiary pt-2" href="">View Details</router-link>
                            </td>
                            </tr>
                            
                        </tbody>
                        </table>
                        <div v-if="valDispute.length === 0" class="flex justify-center w-full">
                            
                            <div v-if="!Invalids" class="text-xl  font-semibold text-center text-t3 my-24 " >
                          <img src="../assets/no-dispute.svg" alt="" class="w-32 m-auto">
                            <p>It appears you have encountered an error!! It appears there are no valid disputes here yet.</p>
                            
                            </div>
                            <div v-if="Invalids" class="text-xl  font-semibold text-center text-t3 my-24 " >
                          <img src="../assets/no-dispute.svg" alt="" class="w-32 m-auto">
                            <p>It appears you have encountered an error!! It appears there are no invalid disputes here yet.</p>
                            
                            </div>
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
          </div>
        </div>
      </div>

                      <button type="button"
                        id="solverModalButton"
                        class=" text-xl h-12 w-12 hidden ml-4 inline-block text-center bg-t3Light text-t3 border-t3 border-solid border-2 font-medium leading-tight uppercase rounded hover:bg-t3 hover:text-white-body2 focus:bg-t3 focus:shadow-lg focus:text-white focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                        data-bs-toggle="modal" data-bs-target="#solverModal">
                        +
                        </button>

            <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="solverModal" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
          <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5 class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">
                  Assign a solver for this dispute
                </h5>
                <button type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body relative p-4">

                <select  v-model="solverID" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                  <option :value="null">Select Solver</option>
                  <option v-for="staff in staffOnly" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                </select>

              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button type="button"
                  id="close"  class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                  data-bs-dismiss="modal">
                  Close
                </button>
                <button type="button"
                  @click="assignSolver" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
          <button id="disputeCloser" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" class="hidden">+</button>
                      <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                            <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                    <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                                        Close Dispute
                                        </h5>
                                        <button type="button" id="dispClose" class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body relative p-4">
                                        <textarea v-model="disputeResolve.resolution" rows="10" cols="12" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            id="exampleFormControlInput1" placeholder="Summary goes here"></textarea>
                                    </div>
                                    <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                        <button type="button" class="inline-block font-medium mt-6 border border-t3-cancel py-2 px-4 text-center ml-auto mr-2 text-t3-cancel rounded-md hover:bg-t3-cancel hover:text-white-body2" data-bs-dismiss="modal"> Close</button>
                                        <button @click="closeDispute" type="button" class="inline-block font-medium mt-6 bg-t3 py-2 px-4 text-center mr-2 text-white rounded-md hover:text-white hover:bg-t3-secondary">Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
    </div>
  </Transition>

</template>

<script>
import { mapState, mapActions , mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
import AssessmentService from "../services/AssessmentsServices";

    export default {
        data() {
            return {
              Invalids:false,
                disputeID:null,
                solverID:null,
                disputeResolve:{
                  id:null,
                  type:null,
                  report:null,
                  resolution:null,
                }
            }
        },
        methods: {
        ...mapActions({
			getdisputes: 'Assessments/getAllDisputes',
      getsettings: 'Settings/getAllSettings',
            getcustomers: 'Users/getAllCustomers',
            getstaff: 'Users/getStaff',

        }),
         issuedBy(id){
                return this.staffOnly.find(cus => cus.id === id)
            },
            solverModalPop(id){
                this.disputeID = id
                document.getElementById('solverModalButton').click()
            },
            closerModalPop(id, type, report){
                this.disputeResolve.id = id
                this.disputeResolve.type = type
                this.disputeResolve.report = report
                document.getElementById('disputeCloser').click()
            },
        assignSolver(){
					AssessmentService.updateDispute(this.disputeID,{responsible:this.solverID}).then(() => {
						Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The dispute has been assigned a solver!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                        this.getdisputes()
                        this.$forceUpdate
					}).catch((error) => {
						let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
					})
				},
        checkSet(type){
          let user = JSON.parse(localStorage.getItem('userdetail'))
          if(type === "external" && user.id === this.settings.external_dispute && !user.is_company){
            return true
          }
          else if(type === "internal" && user.id === this.settings.internal_dispute && !user.is_company){
            return true
          }
          else{
            return false
          }
        },
        closeDispute(){
    if (this.disputeResolve.resolution){
            AssessmentService.closeDispute(this.disputeResolve.id, {summary:this.disputeResolve.resolution}).then(() => {
						Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The dispute has solved!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
            document.getElementById("dispClose").click()
            this.getdisputes()
					}).catch((error) => {
						let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
					})
          }
					else{
          Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Fill in the required fields before closing the dispute!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
        }
				},
       


			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}
        },
        computed:{
            ...mapState({
                disputes: state =>  state.Assessments.disputes,
                customers: state =>  state.Users.customers,
                settings: state =>  state.Settings.settings,
            }),
            ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        "ELE",
        "OPE"
      ]),
      valDispute(){
          if(this.Invalids ){
            return this.disputes.filter(dispute => dispute.valid === true)
            
          }
          else{
            return this.disputes
          }
        },
        },
        
        created(){
            this.getdisputes()
            this.getcustomers()
            this.getstaff()
            this.getsettings()
        }

    }
</script>

<style>
.nav-tabs .nav-link.active {
    color: #0E2D5B;
    border-color: #0E2D5B;
}

.nav-tabs .nav-link {
    color: #9ca4af;
}
</style>
